import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Footer.scss';
import facebook from '../../Images/facebook.png'
import instagram from '../../Images/instagram.png'
import linkedin from '../../Images/linkedin.png'
import twitter from '../../Images/twitter.png'
import threads from '../../Images/threads.png'
import Logo from '../../Images/Logo.png'

// import { useLocation } from "react-router-dom";

export default function Footer({ WebSitePages, navigateTo }) {
    const Navigate = useNavigate()
    return (
        <div className="Footer">
            <div className="Footer2">
                <div className="Footer2Logo" onClick={() => navigateTo("")}>
                    <h1><b></b>BHO Garage Door</h1>
                </div>
            </div>
            <div className="FooterTop">

                <div className="SiteMapDiv">
                    <h1>BHO Garage Door</h1>
                    <div className="SiteMapDivList">
                        <a href="/">Home Page</a>
                        <a href="/book">Book Garage Door Appointment Online In </a>
                        <a href="/contact">Contact Us</a>
                        <a href="/blog">Blog</a>
                        <a href="/faq">Faq</a>
                        <a href="/sitemap">Site Map</a>
                    </div>
                </div>
                <div className="SiteMapDiv">
                    <h1>Services</h1>
                    <div className="SiteMapDivList">
                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Spring">Garage Door Broken Spring</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Spring-Repair">Garage Door Spring Repair</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Rollers">Garage Door Broken Rollers</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Cables">Garage Door Broken Cables</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Hinges">Garage Door Broken Hinges</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Drums">Garage Door Broken Drums</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Off-Track">Garage Door Off Track</a>
                        <a className="Header1BoxMenuBox" href="/service/Damage-Garage-Door-Section">Damage Garage Door Section</a>
                        <a className="Header1BoxMenuBox" href="/service/New-Garage-Door-Installation">New Garage Door Installation</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Wont-Open-Or-Close">Garage Door Opener Won't Open Or Close</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Remote-Is-Not-Working">Garage Door Opener Remote Is Not Working</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Keypad-Is-Not-Working">Garage Door Opener Keypad Is Not Working</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Maintenance">Garage Door Maintenance</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Installation">Garage Door Installation</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Stripped-Gear">Garage Door Opener Stripped Gear</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Installation">Garage Door Opener Installation</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Repair">Garage Door Opener Repair</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Repair">Garage Door Repair</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Services">Garage Door Services</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Near-me">Garage Door Near me</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Spring-Replacement">Garage Door Spring Replacement</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Replacement">Garage Door Replacement</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Chain-Repair">Garage Door Opener Chain Repair</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Belt-Repair">Garage Door Opener Belt Repair</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Residential">Garage Door Residential</a>
                        <a className="Header1BoxMenuBox" href="/service/Commercial-Garage-Door">Commercial Garage Door</a>
                        <a className="Header1BoxMenuBox" href="/service/Residential-Garage-Doors">Residential Garage Doors</a>
                    </div>
                </div>
                <div className="SiteMapDiv">
                    <h1>Contact Us</h1>
                    <div className="SiteMapDivList">
                        <a target='_blank' href="">
                            
                        </a>
                        <a target='_blank' href="tel:314-350-6643">
                            314-350-6643
                        </a>
                        <a target='_blank' href="mailto:info@bhogaragedoor.com">
                            info@bhogaragedoor.com
                        </a>
                    </div>
                </div>

            </div>
            <div className="Footer3">
                <h1>Copyright © 2023 Garage Door. All rights reserved.</h1>
            </div>
        </div>
    )
}
