import React, { useState } from 'react'
import closeMenu from '../../Images/closeMenu.png'
import next from '../../Images/next.png'
import telephone from '../../Images/telephone.png'
import book from '../../Images/book.png'
import tiktok from '../../Images/tiktok.png';
import facebook from '../../Images/facebook.png';
import instagram from '../../Images/instagram.png';
import './Menu.scss';
import $ from 'jquery';

export default function Menu({ navigateTo, setShowMenu, WebSitePages }) {

    const [showMakers, setshowMakers] = useState(false);
    return (
        <div className="Menu">
            <div className="MenuBoxShadow" onClick={() => setShowMenu(false)}></div>
            <div className="MenuList">
                <div className="MenuListTop">
                    <div className="MenuMain">
                        <div className="MenuMainBox">
                            <a href='/'>Home</a>
                        </div>
                        <div className="MenuMainBox" onClick={() => {
                            setshowMakers(true)
                        }}>
                            <h1 >Services</h1>
                            <img src={next} alt="" />
                        </div>
                        <div className="MenuMainBox">
                            <a href='/contact'>Contact Us</a>
                        </div>
                        <div className="MenuMainBox">
                            <a href='/book'>Book An Appointment</a>
                        </div>
                        <div className="MenuMainBox">
                            <a href='/blog'>Blog</a>
                        </div>
                    </div>
                    <div className="MenuFooter">
                        <h5>Copyright © 2024 Garage Door. All rights reserved.</h5>
                    </div>

                    {/* showCategories */}
                    {showMakers ?
                        <div className="MenuMakers">
                            <div className="MenuMakersHeader">
                                <img src={next} alt="" onClick={() => {
                                    setshowMakers(false)
                                }} />
                                <h1 onClick={() => {
                                    // navigateTo("")
                                }}>Services</h1>
                            </div>
                            <div className="MenuMakersList">
                                <div className="MenuMakersListBox">
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Spring">Garage Door Broken Spring</a>
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Spring-Repair">Garage Door Spring Repair</a>
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Rollers">Garage Door Broken Rollers</a>
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Cables">Garage Door Broken Cables</a>
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Hinges">Garage Door Broken Hinges</a>
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Drums">Garage Door Broken Drums</a>
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Off-Track">Garage Door Off Track</a>
                                    <a className="Header1BoxMenuBox" href="/service/Damage-Garage-Door-Section">Damage Garage Door Section</a>
                                    <a className="Header1BoxMenuBox" href="/service/New-Garage-Door-Installation">New Garage Door Installation</a>
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Wont-Open-Or-Close">Garage Door Opener Won't Open Or Close</a>
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Remote-Is-Not-Working">Garage Door Opener Remote Is Not Working</a>
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Keypad-Is-Not-Working">Garage Door Opener Keypad Is Not Working</a>
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Maintenance">Garage Door Maintenance</a>
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Installation">Garage Door Installation</a>
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Stripped-Gear">Garage Door Opener Stripped Gear</a>
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Installation">Garage Door Opener Installation</a>
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Repair">Garage Door Opener Repair</a>
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Repair">Garage Door Repair</a>
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Services">Garage Door Services</a>
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Near-me">Garage Door Near me</a>
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Spring-Replacement">Garage Door Spring Replacement</a>
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Replacement">Garage Door Replacement</a>
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Chain-Repair">Garage Door Opener Chain Repair</a>
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Belt-Repair">Garage Door Opener Belt Repair</a>
                                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Residential">Garage Door Residential</a>
                                    <a className="Header1BoxMenuBox" href="/service/Commercial-Garage-Door">Commercial Garage Door</a>
                                    <a className="Header1BoxMenuBox" href="/service/Residential-Garage-Doors">Residential Garage Doors</a>
                                </div>

                            </div>

                        </div>
                        : null}
                </div>
                <div className="MenuListFooter">
                    <p>
                        Monday - Sunday
                        <br />
                        8:00 a.m. - 8:00 p.m. EST
                    </p>
                    <a href="tel:314-350-6643">CALL NOW 314-350-6643</a>
                    <a href="/book">BOOK AN APPOINTMENT</a>
                </div>

            </div>
        </div>
    )
}
