import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import './SiteMap.scss';

// import { useLocation } from "react-router-dom";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useEffect } from 'react';


export default function SiteMap({ Blogs, FaqList, WebSitePages }) {
    const location = useLocation()
    const [SiteMapToArray, setSiteMapToArray] = useState([]);


    useEffect(() => {
        if (location.pathname.split('/')[2] == "website" || location.pathname.split('/')[1] == "sitemaps" || location.pathname.split('/').length <= 2 && location.pathname.split('/')[1] == "sitemap") {
            setSiteMapToArray(WebSitePages)
        } else if (location.pathname.split('/')[2] == "solutions") {
            setSiteMapToArray(WebSitePages)
        } else if (location.pathname.split('/')[2] == "blog") {
            setSiteMapToArray(Blogs)
        } else if (location.pathname.split('/')[2] == "faq") {
            setSiteMapToArray(FaqList)
        } else if (location.pathname.split('/')[2] == "socialmedia") {
            setSiteMapToArray(WebSitePages)
        }
    }, [location.pathname]);

    return (
        <div className="SiteMap">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Garage Door | Site Map</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Garage Door | Site Map" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Garage Door | Site Map" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.bhogaragedoor.com/sitemap" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Explore the comprehensive sitemap for Garage Door. Find detailed links to our Garage Dooring, Repair, inspection, and repair services to ensure your home's safety and efficiency." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Explore the comprehensive sitemap for Garage Door. Find detailed links to our Garage Dooring, Repair, inspection, and repair services to ensure your home's safety and efficiency." data-react-helmet="true" />
                <meta name="keywords" content="Garage Dooring, Garage Door, Garage Doorinspection, Garage Door repair, Garage Door maintenance, Creosote removal, Garage Doorsafety, Garage Doorflue Repair, Soot removal, Garage Door Maintenance services" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Garage Door | Site Map" data-react-helmet="true" />
            </Helmet>
            <div className="SiteMapMenu">
                <a href="/">Website</a>
            </div>
            <div className="SiteMapDes">
                <div className='SiteMapDesBox'>
                    <a href="/">Home Page</a>
                    <a href="/contact">Contact Us</a>
                    <a href="/book">Book Garage Door Appointment Online In </a>
                    <a href="/blog">Blog</a>
                    <a href="/faq">Faq</a>
                    <a href="/sitemap">Site Map</a>

                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Spring-Repair">Garage Door Spring Repair</a>
                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Rollers">Garage Door Broken Rollers</a>
                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Cables">Garage Door Broken Cables</a>
                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Hinges">Garage Door Broken Hinges</a>
                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Drums">Garage Door Broken Drums</a>
                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Off-Track">Garage Door Off Track</a>
                    <a className="Header1BoxMenuBox" href="/service/Damage-Garage-Door-Section">Damage Garage Door Section</a>
                    <a className="Header1BoxMenuBox" href="/service/New-Garage-Door-Installation">New Garage Door Installation</a>
                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Wont-Open-Or-Close">Garage Door Opener Won't Open Or Close</a>
                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Remote-Is-Not-Working">Garage Door Opener Remote Is Not Working</a>
                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Keypad-Is-Not-Working">Garage Door Opener Keypad Is Not Working</a>
                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Maintenance">Garage Door Maintenance</a>
                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Installation">Garage Door Installation</a>
                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Stripped-Gear">Garage Door Opener Stripped Gear</a>
                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Installation">Garage Door Opener Installation</a>
                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Repair">Garage Door Opener Repair</a>
                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Repair">Garage Door Repair</a>
                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Services">Garage Door Services</a>
                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Near-me">Garage Door Near me</a>
                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Spring-Replacement">Garage Door Spring Replacement</a>
                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Replacement">Garage Door Replacement</a>
                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Chain-Repair">Garage Door Opener Chain Repair</a>
                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Belt-Repair">Garage Door Opener Belt Repair</a>
                    <a className="Header1BoxMenuBox" href="/service/Garage-Door-Residential">Garage Door Residential</a>
                    <a className="Header1BoxMenuBox" href="/service/Commercial-Garage-Door">Commercial Garage Door</a>
                    <a className="Header1BoxMenuBox" href="/service/Residential-Garage-Doors">Residential Garage Doors</a>

                    {/* faq */}
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_do_I_know_if_my_garage_door_needs_repair_in_0">How do I know if my Garage Door needs repair</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Can_I_repair_a_garage_door_myself_in_1">Can I repair a Garage Door myself</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_long_does_a_typical_garage_door_repair_take_in_2">How long does a typical Garage Door repair take</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_are_common_garage_door_problems_in_3">What are common Garage Door problems</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_much_does_garage_door_repair_cost_in_4">How much does Garage Door repair cost</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_types_of_garage_door_services_are_offered_in_5">What types of Garage Door services are offered</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Why_is_regular_maintenance_important_for_garage_doors_in_6">Why is regular maintenance important for Garage Doors</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Can_I_schedule_same-day_garage_door_service_in_7">Can I schedule same-day Garage Door service</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_should_I_look_for_in_a_garage_door_service_provider_in_8">What should I look for in a Garage Door service provider</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_often_should_I_have_my_garage_door_serviced_in_9">How often should I have my Garage Door serviced</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_causes_garage_door_springs_to_break_in_10">What causes Garage Door springs to break</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_can_I_tell_if_my_garage_door_spring_is_broken_in_11">How can I tell if my Garage Door spring is broken</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Is_it_safe_to_operate_a_garage_door_with_a_broken_spring_in_12">Is it safe to operate a Garage Door with a broken spring</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_long_does_it_take_to_replace_a_broken_garage_door_spring_in_13">How long does it take to replace a broken Garage Door spring</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_is_the_cost_to_replace_a_garage_door_spring_in_14">What is the cost to replace a Garage Door spring</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_causes_a_garage_door_to_go_off_track_in_15">What causes a Garage Door to go off track</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_should_I_do_if_my_garage_door_goes_off_track_in_16">What should I do if my Garage Door goes off track</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_is_a_garage_door_put_back_on_track_in_17">How is a Garage Door put back on track</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Can_I_prevent_my_garage_door_from_going_off_track_in_18">Can I prevent my Garage Door from going off track</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_much_does_it_cost_to_fix_a_garage_door_that_is_off_track_in_19">How much does it cost to fix a Garage Door that is off track</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_are_the_best_materials_for_residential_garage_doors_in_20">What are the best materials for residential Garage Doors</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_do_I_choose_the_right_style_of_garage_door_for_my_home_in_21">How do I choose the right style of Garage Door for my home</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Are_insulated_garage_doors_necessary_in_22">Are insulated Garage Doors necessary</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_often_should_I_replace_my_residential_garage_door_in_23">How often should I replace my residential Garage Door</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Can_I_customize_my_residential_garage_door_in_24">Can I customize my residential Garage Door</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_types_of_garage_door_openers_are_available_in_25">What types of Garage Door openers are available</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_do_I_know_if_I_need_to_replace_my_garage_door_opener_in_26">How do I know if I need to replace my Garage Door opener</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Can_I_install_a_garage_door_opener_myself_in_27">Can I install a Garage Door opener myself</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_are_the_benefits_of_a_smart_garage_door_opener_in_28">What are the benefits of a smart Garage Door opener</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_much_does_it_cost_to_install_a_garage_door_opener_in_29">How much does it cost to install a Garage Door opener</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_factors_should_I_consider_when_installing_a_new_garage_door_in_30">What factors should I consider when installing a new Garage Door</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/How_long_does_it_take_to_install_a_new_garage_door_in_31">How long does it take to install a new Garage Door</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Can_I_replace_my_garage_door_myself_in_32">Can I replace my Garage Door myself</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/What_is_the_cost_of_installing_a_new_garage_door_in_33">What is the cost of installing a new Garage Door</a>
                    <a Header1BoxMenuBox="Header1BoxMenuBox" href="/faq/Are_there_any_local_regulations_for_garage_door_installation_in_34">Are there any local regulations for Garage Door installation</a>

                    {/* blog */}
                    <a className='Header1BoxMenuBox' href="/blog/understanding_and_addressing_garage_door_broken_springs_in_,_ca:_safety,_repair,_and_prevention_3">Comprehensive Guide to Garage Door Repair in: Keeping Your Home Secure and Functional</a>
                    <a className='Header1BoxMenuBox' href="/blog/the_ultimate_guide_to_garage_door_installation_in_,_ca:_transforming_your_home’s_curb_appeal_and_security_2">Comprehensive Guide to Garage Door Services in: Ensuring Safety, Functionality, and Longevity</a>
                    <a className='Header1BoxMenuBox' href="/blog/comprehensive_guide_to_garage_door_services_in_,_ca:_ensuring_safety,_functionality,_and_longevity_1">The Ultimate Guide to Garage Door Installation in: Transforming Your Home’s Curb Appeal and Security</a>
                    <a className='Header1BoxMenuBox' href="/blog/comprehensive_guide_to_garage_door_repair_in_,_ca:_keeping_your_home_secure_and_functional_0">Understanding and Addressing Garage Door Broken Springs in: Safety, Repair, and Prevention</a>
                </div>



            </div>
            <NavigatorPath />
        </div>
    )
}
